header{
    display: flex;
    flex-direction: row;
    padding: 2em;
    justify-content: space-between;
    align-items: center;
}
header h1{
    text-align: center;
    color: whitesmoke;
}
.filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}