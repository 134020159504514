@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  min-height: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
  background-image: linear-gradient(to right top, #3b444b, #394247, #384044, #373d40, #363b3d, #34393b, #323738, #303536, #2d3233, #293031, #262d2e, #232b2b);
  background-repeat: no-repeat;
  min-height: 100%;
}


.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.post-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);

    border: 2pt solid gray;
    box-shadow: 0 0 3px white;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}
.post-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
header{
    display: flex;
    flex-direction: row;
    padding: 2em;
    justify-content: space-between;
    align-items: center;
}
header h1{
    text-align: center;
    color: whitesmoke;
}
.filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}
button{
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.post-button{
    flex-basis: 25%;
    align-items: center;
    justify-content: center;
    padding: 0.75em;
    grid-column-gap: 1em;
    column-gap: 1em;

    background-color: cornflowerblue;
    color: white;
    border: 1pt solid black;
    box-shadow: 0 0 3px black;
    font-size: 1.5em;

    transition: 0.25s;
}
.post-button:hover{
    background-color: #0e50f5;
    box-shadow: 0 0 7px 2px cornflowerblue;
}

.submit-form{
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    grid-column-gap: 1em;
    column-gap: 1em;

    color: whitesmoke;
    border: 1pt solid black;
    box-shadow: 0 0 5px 1px black;
    padding: 0.75em;
    font-size: 1.25em;
    background-color: #2d792d;
}
.submit-form.disabled{
    cursor: not-allowed;
    background-color: gray;
}

.delete-form{
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    grid-column-gap: 1em;
    column-gap: 1em;

    color: whitesmoke;
    border: 1pt solid black;
    box-shadow: 0 0 5px 1px black;
    padding: 0.75em;
    font-size: 1.25em;
    background-color: #d21b1b;
}

.filter-option{
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    color: whitesmoke;
    border: 1pt solid black;
    box-shadow: 0 0 5px 1px black;
    padding: 0.5em;
    font-size: 1em;
    background-color: grey;
}

.filter-option.selected{
    color: white;
    background-color: orangered;
}
.post-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1em;
    margin: 3em 0;
    color: whitesmoke;
    position: relative;
}

.date{
    font-size: 2em;
    text-align: center;
    width: 20%;
}
.post{
    padding: 2em;
    border: 1pt solid whitesmoke;
    width: 50%;
}

.title{
    width: 80%;
    font-size: 1.75em;
}

.title-score{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content{
    word-break: break-all;
}
.username{
    font-size: 1.25em;
}

.separator{
    width: 10%;
}


.score{
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.vote{
    font-size: 2em;
    cursor: pointer;
}
.up-counter:hover, .down-counter:hover{
    color: orangered;
}
.selected{
    color: orangered;
}
.list-posts{
    position: relative;
    padding: 0 1em;
    margin: 2em 0;
}
.vertical-line{
    width: 10%;
    margin: 0 1em;
    min-height: 60vh;
    height: 100%;
    border-left: 3pt solid white;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 20%;
}
.no-post{
    font-size: 2.5em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}
.form-section{
    display: flex;
    flex-direction: column;
    padding: 1.5em;
}
.form-action{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em;
}
.form-section input, .form-section textarea{
    width: 100%;
    padding: 0.75em;
    font-size: 1.5em;
}
.form-section label{
    margin-bottom: 0.5em;
    font-size: 1.25em;
}

textarea{
    resize: none;
}

.input-container{
    position: relative;
}
.char-counter{
    position: absolute;
    right: 2.5%;
    transform: translateY(50%);
}
#login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 1em;
    row-gap: 1em;
    height: 100vh;
    color: white;
}
#login h1{
    font-size: 3.5em;
}
#login p{
    font-size: 1.5em;
}

#login .username-box{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 40%;
}

#login input{
    background-image: linear-gradient(to right top, #3b444b, #394247, #384044, #373d40, #363b3d, #34393b, #323738, #303536, #2d3233, #293031, #262d2e, #232b2b);
    background-repeat: no-repeat;
    margin: 1em;
    padding: 1em;
    color: white;
    font-size: 1.75em;
    border: 2pt solid orangered;
    text-align: center;
    width: 100%;
}


#login .user-counter{
    position: absolute;
    top: 52%;
    right: 5%;
    transform: translate(-50%, -50%);
}
#login .error{
    color: orangered;
    font-size: 1.25em;
}

.not-found{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 1em;
    row-gap: 1em;
    height: 100vh;
}
.not-found h1{
    font-size: 3em;
    color: white;
}
.not-found .go-back{
    font-size: 1.5em;
    color: orangered;
}
