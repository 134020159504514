@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  min-height: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
  background-image: linear-gradient(to right top, #3b444b, #394247, #384044, #373d40, #363b3d, #34393b, #323738, #303536, #2d3233, #293031, #262d2e, #232b2b);
  background-repeat: no-repeat;
  min-height: 100%;
}