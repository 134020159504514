button{
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.post-button{
    flex-basis: 25%;
    align-items: center;
    justify-content: center;
    padding: 0.75em;
    column-gap: 1em;

    background-color: cornflowerblue;
    color: white;
    border: 1pt solid black;
    box-shadow: 0 0 3px black;
    font-size: 1.5em;

    transition: 0.25s;
}
.post-button:hover{
    background-color: #0e50f5;
    box-shadow: 0 0 7px 2px cornflowerblue;
}

.submit-form{
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    column-gap: 1em;

    color: whitesmoke;
    border: 1pt solid black;
    box-shadow: 0 0 5px 1px black;
    padding: 0.75em;
    font-size: 1.25em;
    background-color: #2d792d;
}
.submit-form.disabled{
    cursor: not-allowed;
    background-color: gray;
}

.delete-form{
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    column-gap: 1em;

    color: whitesmoke;
    border: 1pt solid black;
    box-shadow: 0 0 5px 1px black;
    padding: 0.75em;
    font-size: 1.25em;
    background-color: #d21b1b;
}

.filter-option{
    align-items: center;
    gap: 0.5em;
    color: whitesmoke;
    border: 1pt solid black;
    box-shadow: 0 0 5px 1px black;
    padding: 0.5em;
    font-size: 1em;
    background-color: grey;
}

.filter-option.selected{
    color: white;
    background-color: orangered;
}